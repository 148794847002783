<template>
  <div>
    <b-row>
      <!-- <totalSales /> -->
      <analytics class="w-100" />
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import totalSales from "@/views/home pages/total sales.vue";
import analytics from "@/views/analytics/Analytics.vue";
import statisticApi from "@/apiServices/statisticsApi";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    totalSales,
    analytics,
  },
  created() {
    this.getStatistics();
  },
  methods: {
    getStatistics() {
      statisticApi.getStatistics().then((response) => {
        if (response) {
          localStorage.setItem("statistics", JSON.stringify(response.data));
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Welcome",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
  },
};
</script>

<style></style>
